import PlacesAutocomplete from "react-places-autocomplete";
import { useOrderModal } from "../order/order.modal";
import { Input } from "@/components/ui/input";
import FilterOptions from "../filter-options";
import GoogleMapsLoader from "@/config/api-loader";
export const OrderView = () => {
  const { isLoaded } = GoogleMapsLoader();
  const {address, handleChangeAddress, handleSelectAddress, handleSubmit, isLoading } = useOrderModal()
  if(!isLoaded) return null
  return (
    <aside className="flex flex-col gap-2 z-[100] ">
        <PlacesAutocomplete
          value={address}
          onChange={handleChangeAddress}
          onSelect={handleSelectAddress}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="relative ">
              <Input
                {...getInputProps({
                  disabled: loading || isLoading,
                  placeholder: "Selecione o endereço",
                  className: "location-search-input"
                })}
              />
              <div className={`autocomplete-dropdown-container w-full absolute flex flex-col px-2 py-3 p-4 max-h-[100] ${suggestions.length == 0 && " sr-only"}`}>
                {suggestions?.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      <FilterOptions onSubmit={handleSubmit} isLoading={isLoading} isOrder />
    </aside>)
}