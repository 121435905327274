import { User } from "@/@types/next-auth";
import { jwtDecode } from 'jwt-decode';
import { create } from "zustand";

type SessionStore = {
  user: User;
  token: string 
  setToken: (token: string | undefined) => void;
};

export const useSessionStore = create<SessionStore>((set) => ({
  user: {} as User,
  token: '',
  setToken: (token: string | undefined) => {
    try {
      if(token){
        const user = jwtDecode(token) as User || null; 
        if(!user) return
        set({ user, token });
      }
      return;
    } catch (error) {
      console.log(error)
      return
    }
  },
}));